import axios from '../libs/axios';

// 订单列表
export const orderList = (data) => {
  return axios.get('/api/trd/order/list', data);
};

// 订单信息
export const orderInfo = (orderId) => {
  return axios.get(`/api/trd/order/info/${orderId}`);
};

// 创建订单
export const orderCreate = (data) => {
  return axios.post('/api/trd/order/create', data);
};

// 创建订单
export const timesCardOrderCreate = (data) => {
  return axios.post('/api/trd/order/timesCard/create', data);
};

// 支付订单
export const orderpay = (data) => {
  return axios.post('/api/trd/orderpay/save', data);
};

// 查看订单支付结果
export const haspaying = (data) => {
  return axios.get('/api/trd/orderpay/haspaying', data);
};

// 取消订单
export const orderCancel = (data) => {
  return axios.get('/api/trd/order/cancel', data);
};

// 删除订单
export const orderDelete = (data) => {
  return axios.get('/api/trd/order/delete', data);
};

// 退款
export const orderRefund = (data) => {
  return axios.post('/api/trd/orderpay/refund', data);
};

// 订单卡丁车队列
export const queryPlayQueue = (data) => {
  return axios.get('/api/trd/order/queryPlayQueue', data);
};

// 订单队列-手动完成
export const orderComplete = (data) => {
  return axios.post('/api/trd/order/play/complete', data);
};

// 订单导出
export const orderExport = (data) => {
  return axios.get('/api/trd/order/export', data);
};
